import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom';
import './index.css';
import CodeWith from './components/codewith/codewith';
//import Resume from './components/resume/resume';
import Notfound from './components/notfound/notfound';
import Admin from './components/admin/admin';
import CPPath from './components/CompetitivePath/CPPath';
import Vote from './components/Vote/Vote';
import Resume from './components/resume/resume';
import * as serviceWorker from './serviceWorker';

const routing = (
  <Router>
    <div>
      <Switch>
	      <Route exact path="/" component={CodeWith} title="Code Pandas | Learn Programming"/>
        <Route path='/admin' component={Admin}/>
        <Route path='/cp' component={CPPath} title="Code Pandas | Learn Programming"/>
        <Route path ='/vote' 
              render={props => (
                <Vote {...props} title="V-Vote - Create Communities and Polls | Anonymous Chat and Polling Website"/>
              )}
              
        />
        <Route 
          path ='/resume' 
          render={props => (
            <Resume {...props} title="Himanshu Tiwari | There is nothing a code can't do!"/>
          )}
        />
	      <Route component={Notfound} />
      </Switch>
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
