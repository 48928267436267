import React, { Component } from 'react';
import firebase from 'firebase';

class CreatePoll extends Component{
  state = {
    user: '',
    community : '',
    pollTitle: '',
    pollOptions: [],
    newOptionEnabled : true,
    addRemove: "-",
    numOptions: 0,
    newOption: '',
    creatingPoll: false
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var _this = this;
    var userObj = {username : _this.props.username, useremail: _this.props.useremail, userpicture: _this.props.userpicture};
    this.setState({user: userObj, community: this.props.community});
    console.log(this.props);
    this.createNewPoll = this.createNewPoll.bind(this);
    this.handleNewOption = this.handleNewOption.bind(this);
    this.addOption = this.addOption.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
    const loader = document.querySelector('.loader-container');
    setTimeout(function () {
      loader.classList.add('loader--hide');
    }, 100);
  }
  componentDidUpdate() {
    if(this.state.community != this.props.community){
        this.setState({community: this.props.community});
        this.setState({ pollTitle: '',
                        pollOptions: [],
                        newOptionEnabled : true,
                        addRemove: "-",
                        numOptions: 0,
                        newOption: ''})
    }
  }
  createNewPoll(pollObj) {
    var createPoll = firebase.functions().httpsCallable('createPoll');
    var _this = this;
    if(this.state.creatingPoll || this.state.pollTitle.trim() == '' || this.state.pollOptions.length === 0){
        console.log("Validation failed");
        return;
    }
    this.setState({creatingPoll: true});
    createPoll({community: this.state.community, pollObj: pollObj}).then(function(result) {
        console.log("Poll Created");
        console.log(result);
        _this.props.callBack(result.data);
        _this.setState({community: _this.props.community});
        _this.setState({ pollTitle: '',
                        pollOptions: [],
                        newOptionEnabled : true,
                        addRemove: "-",
                        numOptions: 0,
                        newOption: ''});
        _this.setState({creatingPoll: false});
    }).catch(error => {
        _this.setState({creatingPoll: false});
        console.log(error);
        console.log("Error");
    })
  }
  handleNewOption(e) {
    if(this.state.newOption) {
        this.setState(prevState => ({
            pollOptions: [...prevState.pollOptions, {title: this.state.newOption, id: this.state.numOptions + 1}]
        }))
        this.setState({numOptions: this.state.numOptions+1, newOption: ''});
    }
    this.setState({newOption: ''});

    e.preventDefault();
  }
  addOption(e) {
    var buttonSign = (this.state.newOptionEnabled ? '+' : '-');
    this.setState({newOptionEnabled: !this.state.newOptionEnabled, addRemove: buttonSign});
    e.preventDefault();
  }
  handleSubmit(e) {
    console.log(this.state);
    console.log(this.props.community);

    var pollObj = {community: this.state.community, options: this.state.pollOptions, title: this.state.pollTitle};
    this.createNewPoll(pollObj);
    e.preventDefault();
  }
  componentWillUnmount() {
    this.setState({user: '',
    community : '',
    pollTitle: '',
    pollOptions: [],
    newOptionEnabled : true,
    addRemove: "-",
    numOptions: 0,
    newOption: ''})
  }

  render() {
      var _this = this;
      if(this.state.community) {
        return (
          <div className="comm-detail-body" style={{margin: "10px"}}>
                <img className="member-picture" src={_this.state.user.userpicture} alt="dp"></img>
                <form onSubmit={this.handleSubmit} style={{width: "100%"}}>
                    <textarea name="title" value={this.state.pollTitle} rows={3} placeholder="Write something here...
यहां कुछ लिखिए..." onChange={(e)=> {this.setState({pollTitle: e.target.value})}} style={{"border": 0, "fontFamily": "Helvetica, Arial, sans-serif", "fontSize": "16px", width: "100%"}}/>
                    <ul className="option-tags">
                        { this.state.pollOptions.map(function (i) {
                            return <div><li>{i.title}
                                <button className="opt-cancel-btn" value={i.id} onClick={(e) => { _this.setState({pollOptions: _this.state.pollOptions.filter(function(opt) { 
                                    return opt.id != e.target.value;
                                })}); e.preventDefault();} }>x</button> 
                                </li>   
                            </div>       
                        })}
                        {this.state.newOptionEnabled && 
                            <div>
                                <li>
                                  <input type="text" name="newOption" value={this.state.newOption} placeholder="Add Option" style={{"border": 0, fontSize: "13px", padding:0, margin:0}} onChange={(e)=> {this.setState({newOption: e.target.value})} }></input>
                                  <button className="add-option-btn" onClick={this.handleNewOption}>Ok</button>
                                </li>
                            </div>
                        }
                    </ul>
                    <input className="poll-submit-btn" type="submit" value="Create Poll"/>

                </form>                
            </div>
          )
  
      }
      else {
        return (
          <h2>Please join a community or select one of your community above to create a Poll!</h2>
        )
      }    
  }
}

export default CreatePoll;
