import React, { Component } from 'react';
import portraitface from './images/portrait-footer.png';
import starwhite from './images/star-white.png';
import spaceship from './images/spaceship.png';
import floorpanel from './images/floor-panel.png';
import ironblocks from './images/iron-blocks.png';
import layoutabouttext from './images/layout-about.png';
import building1 from './images/building1.png';
import building2 from './images/building2.png';
import himanshutext from './images/himanshu-text.png';
import moon from './images/moon.png';
import spaceyfwd from './images/astro-sprite.png';
import spaceybwd from './images/astro-sprite-left.png';
import treeb from './images/tree-dark-b.png';
import treec from './images/tree-dark-c.png';
import treed from './images/tree-dark-d.png';
import treebe from './images/tree-bright-e.png';


import spaceyrt from './images/spaceywchat.png';
import spaceylt from './images/spacey-left.png';
import legoballoon from './images/lego-balloon.png';
import './resume.css';
import { findDOMNode } from 'react-dom';
import $ from 'jquery';

class Resume extends Component {
  state = {
    isAnimating: false,
    isMovingRight: false,
    scrollAnimating: false,
    dh: 1024
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    //window.callbackFn();
    document.title = this.props.title;
    document.querySelector("[name='viewport']").setAttribute('content', 'width=1024');
    var dh =  window.innerHeight || document.documentElement.clientHeight;
    this.setState({dh: dh});
    
    const loader = document.querySelector('.loader-container');
    setTimeout(function () {
      loader.classList.add('loader--hide');
    }, 100);

    setTimeout(function(){
        $(".control-text").hide();
    }, 200000);
    this.handleSwipeOrKeyPress = this.handleSwipeOrKeyPress.bind(this);
    this.AnimateStripe = this.AnimateStripe.bind(this);
    this.scrollDownInfo = this.scrollDownInfo.bind(this);
    // to add touch events
    (function(d){
         var
         ce=function(e,n){var a=document.createEvent("CustomEvent");a.initCustomEvent(n,true,true,e.target);e.target.dispatchEvent(a);a=null;return false},
         nm=true,sp={x:0,y:0},ep={x:0,y:0},
         touch={
          touchstart:function(e){sp={x:e.touches[0].pageX,y:e.touches[0].pageY}},
          touchmove:function(e){nm=false;ep={x:e.touches[0].pageX,y:e.touches[0].pageY}},
          touchend:function(e){if(nm){ce(e,'fc')}else{var x=ep.x-sp.x,xr=Math.abs(x),y=ep.y-sp.y,yr=Math.abs(y);if(Math.max(xr,yr)>20){ce(e,(xr>yr?(x<0?'swl':'swr'):(y<0?'swu':'swd')))}};nm=true},
          touchcancel:function(e){nm=false}
         };
         for(var a in touch){d.addEventListener(a,touch[a],false);}
    })(document);

    function showControlText() {
        $(".control-text").show();
    }

    function handleVertical(layer, movement, vpOffset) {
        var translate3d = 'translate3d(' + vpOffset + 'px ,'  + movement + 'px, 0)';

        layer.style['-webkit-transform'] = translate3d;
        layer.style['-moz-transform'] = translate3d;
        layer.style['-ms-transform'] = translate3d;
        layer.style['-o-transform'] = translate3d;
        layer.style.transform = translate3d; 

    }
    function initializeHorizontalLayers() {
        var depth, i, layer, layers, len;
        layers = document.getElementsByClassName("horizontal-layer");
        for (i = 0, len = layers.length; i < len; i++) {
            layer = layers[i];
            var leftOffset = layer.getAttribute('data-leftoffset');
            var bottomOffset = layer.getAttribute('data-bottomoffset');
            var topOffset = layer.getAttribute('data-topoffset');
            var height = layer.getAttribute('data-height');
            var leftInit = layer.getAttribute('data-leftoffset-init');
            var OffsetObj = {
                left : leftInit || leftOffset
            }
            $(layer).offset(OffsetObj);
            if(topOffset){
                $(layer).css({top : topOffset + "px" });
            }
            if(bottomOffset){
                $(layer).css({bottom : bottomOffset + "px" });
            }
            if(height) {
              $(layer).css({height : height });
            }
        }
    }
    var bodyWidth = document.body.clientWidth;
    var bodyHeight = document.body.clientHeight;

    var placeStarsRandomly = function (star, maxHeight) {
        var bodyWidth = document.body.clientWidth;
        var bodyHeight = document.body.clientHeight;
        star.each(function() {
            var ele = $(this);
            var height = Math.floor((Math.random()*maxHeight) + 3);
            var randPosX = Math.floor((Math.random()* bodyWidth) * 3);
            var randPosY = Math.floor((Math.random()*bodyHeight * 6));

            ele.height(height);
            ele.css('left', randPosX + "px");
            ele.css('top', randPosY);
        });  
    }
    
    placeStarsRandomly($(".staticstar"), 16);
    initializeHorizontalLayers();
    document.addEventListener('swl', this.handleSwipeOrKeyPress,true);
    document.addEventListener('swr',this.handleSwipeOrKeyPress,true);
    document.onkeydown = this.handleSwipeOrKeyPress;
    
    setTimeout(function() {
      $('.htext').animate({bottom: 30, left: 900}, 700, "swing");
    }, 100);
  }  
  
  scrollDownInfo(ele, expand) {
    var pos = ele.height();
    if(this.state.scrollAnimating) 
      return;
    var _this = this;    
    var dh =  window.innerHeight || document.documentElement.clientHeight;
    if(expand && ele.height() < 400){
      this.setState({scrollAnimating: true})
      $('.scroll-rope').animate({bottom: "700px"}, 400);
      $('.scroll-rope2').animate({bottom: "600px"}, 400);
      $('.scroll-anchor').animate({bottom: "650px"}, 400);
      ele.animate({top: dh-600}, 400, function() {
        setTimeout(function() {
          ele.animate({height: "300px"}, 500, "swing", function() {
            _this.setState({scrollAnimating: false});
            ele.children('div')[0].style.display = "";
          });  
        }, 200)
      })
    }
    else if(expand === false && ele.height() >= 20) {
      this.setState({scrollAnimating: true});
      $('.scroll-rope').animate({bottom: dh+200}, 200);
      $('.scroll-rope2').animate({bottom: dh+50}, 200);
      $('.scroll-anchor').animate({bottom: dh+100}, 200);
      ele.animate({top: -300}, 200, function() {
        ele.animate({height: "19px"}, 200, "swing", function() {
          _this.setState({scrollAnimating: false});        
          ele.children('div')[0].style.display = "none";
        });
      })
    }
  }

  AnimateStripe(i, ele, height, n) {
    if(i == n+1){
        ele.css({"background-position" : "0px 0px" });
        this.setState({isMovingRight: false})
        return;
    }
    var _this = this;
    if(i == 0)
    setTimeout(function() {
        ele.css({"background-position" : "0px " + (i*height - 7)+"px" });
        _this.AnimateStripe(i+1, ele, height, n);
    }, 5);
    if(i==1)
    setTimeout(function() {
      ele.css({"background-position" : "0px " + (i*height - 7)+"px" });
      _this.AnimateStripe(i+1, ele, height, n);
    }, 35);
    if(i==2)
    setTimeout(function() {
        ele.css({"background-position" : "0px " + (i*height - 7)+"px" });
        _this.AnimateStripe(i+1, ele, height, n);
    }, 50);
    if(i==3)
    setTimeout(function() {
      ele.css({"background-position" : "0px " + (i*height - 7)+"px" });
      _this.AnimateStripe(i+1, ele, height, n);
    }, 100);
    if(i==4)
    setTimeout(function() {
      ele.css({"background-position" : "0px " + (i*height - 7)+"px" });
      _this.AnimateStripe(i+1, ele, height, n);
    }, 100);
    if(i==5)
    setTimeout(function() {
      ele.css({"background-position" : "0px " + (i*height - 7)+"px" });
      _this.AnimateStripe(i+1, ele, height, n);
    }, 80);
    if(i==6)
    setTimeout(function() {
      ele.css({"background-position" : "0px " + (i*height - 7)+"px" });
      _this.AnimateStripe(i+1, ele, height, n);
    }, 35);
    if(i==7)
    setTimeout(function() {
      ele.css({"background-position" : "0px " + (i*height - 7)+"px" });
      _this.AnimateStripe(i+1, ele, height, n);
    }, 50);
  }
  handleSwipeOrKeyPress(event) {
      console.log(this.state.isAnimating);
      if(this.state.isAnimating)
        return;
      this.setState({isAnimating: true});
      var depth, i, layer, layers, len, movement, absoluteDistance, scrolls;
      var _this = this;
      absoluteDistance = 100;
      debugger;
      layers = document.querySelectorAll("[data-type='parallax']");
      var mult = (event.keyCode == 40 || event.type == 'swr') ? 1 : event.keyCode == 38 || event.type == 'swl' ?  -1 : 0;
      
      setTimeout(function() {
        if(mult < 0 && _this.state.isMovingRight == false) {
          _this.setState({isMovingRight: true})
          _this.AnimateStripe(0, $("#spacey-right"), 330, 7);
        }
        if(mult > 0 && _this.state.isMovingRight == false) {
          _this.setState({isMovingRight: true})
          _this.AnimateStripe(0, $("#spacey-left"), 330, 7);  
        }
        scrolls = document.querySelectorAll("[data-typ='scrolls']");
        var j = 0, lenn = scrolls.length;
        for (j = 0, lenn = scrolls.length; j < lenn; j++) {
          var bounding = scrolls[j].getBoundingClientRect();
          if ( bounding.right <= ((19 * window.innerWidth)/20 || (19*document.documentElement.clientWidth)/20)) {
            _this.scrollDownInfo($(scrolls[j]), true);          
          } else {
            _this.scrollDownInfo($(scrolls[j]), false);          
          }
        }  
      }, 0);
//      var timout = 0;
      for (i = 0, len = layers.length; i < len; i++) {
        layer = layers[i];
        depth = layer.getAttribute('data-depth');

        if(mult == -1){
          $("#spacey-right").show();
          $("#spacey-left").hide(); 
        } else if(mult==1){
          $("#spacey-right").hide();
          $("#spacey-left").show();
        }
        if(event.type == 'swr' || event.type == 'swl'){
          mult = mult > 0 ? 10 : mult < 0 ? -10 : 0;
          var newOffset = $(layer).offset().left + absoluteDistance * depth * mult * 0.75;

          if(mult == 10 && newOffset > layer.getAttribute('data-leftoffset')){
            break;
          }  
          $(layer).animate({
              left : newOffset,
              "queue": false
            }, 300, 'swing');
        } else {
            var newOffset = $(layer).offset().left + absoluteDistance * depth * mult * 10;
            if(mult == 1 && newOffset > layer.getAttribute('data-leftoffset')){
              continue;
            }  
            $(layer).animate({
              left : newOffset,
              "queue": false
            }, 300, 'linear');
        }
      }
      setTimeout(function() {
        _this.setState({isAnimating: false});
      }, 300);
  };
  renderStars(classNm, numDots) {
      
      var dotsCount = numDots;
      var dotsHtml = [];
       
      for (var i = 0; i < dotsCount; i++) {
        dotsHtml.push(<img className={classNm} src={starwhite}></img>);
      }
      return dotsHtml;
  }
  //fake comment.

 
  render() {

    return (
      <div className="Resume">

        <div className="horizontal-layer hlayer1 stars" data-type='parallax' data-depth='0.01' data-leftoffset='0' data-bottomoffset='20'>
            {this.renderStars("staticstar", 350)}
        </div>
        
        <div className="horizontal-layer hlayer2 buildings htext" data-depth='0.11' data-type='parallax' data-leftoffset-init='150' data-leftoffset='900' data-bottomoffset='-800'>
            <img style={{height: "800px"}} src={himanshutext}/>
        </div>
        <div className="horizontal-layer hlayer2 abouttext" data-depth='0.11' data-type='parallax' data-leftoffset='900' data-bottomoffset='200'>
            <img src={treeb}/>
        </div>  

        <div className="horizontal-layer hlayer2 buildings" data-depth='0.08' data-type='parallax' data-leftoffset='3000' data-bottomoffset='0'>
            <img src={legoballoon}/>
        </div>
        
        <div className="horizontal-layer hlayer2 buildings" data-depth='0.11' data-type='parallax' data-leftoffset='5000' data-bottomoffset='200'>
            <img src={building1}/>
        </div>





        <div className="horizontal-layer hlayer3 layerfloorpanel" data-depth='0.2' data-type='parallax' data-leftoffset='0' data-bottomoffset='0'
            style={{"backgroundImage": "url(" + floorpanel + ")"}}>
        </div>

        <div className="horizontal-layer hlayer4" data-depth='0.15' data-type='parallax' data-leftoffset='2900' data-bottomOffset={this.state.dh+10}>
          <div className="horizontal-layer scroll-rope hlayer4" data-depth='0.15'>

          </div>
          <div className="horizontal-layer scroll-anchor hlayer4" data-depth='0.15'>

          </div>
          <div className="horizontal-layer scroll-rope2 hlayer4" data-depth='0.15'>

          </div>
          <div className="horizontal-layer education hlayer4" data-depth='0.15' data-typ='scrolls'>
              <div className="scroll-content" style={{display: "none"}}>
                <br></br>
                <span style={{fontFamily: "Montserrat", fontSize: "20px", fontWeight: "bold", color:"#fefefe"}}>Master of Technology</span>
                <br></br>
                <span  style={{fontFamily: "Montserrat", fontSize: "30px", fontWeight: "bold"}}>Delhi Technological University</span>
                <br></br>
                <span  style={{fontSize: "17px", color:"#fefefe"}}>August 2019 - May 2021</span>
                <br></br>
                <br></br>
                <br></br>
                <span style={{fontFamily: "Montserrat", fontSize: "20px", fontWeight: "bold", color:"#fefefe"}}>Bachelor of Technology</span>
                <br></br>
                <span  style={{fontFamily: "Montserrat", fontSize: "30px", fontWeight: "bold"}}>Bharati Vidyapeeth's College of Engineering</span>
                <br></br>
                <span  style={{fontSize: "17px",color:"#fefefe"}}>August 2013 - June 2017</span>
              </div>
          </div>
        </div>


        <div className="horizontal-layer hlayer4 abouttext" data-depth='0.2' data-type='parallax' data-leftoffset='3100' data-bottomoffset='200'>
            <img src={treeb}/>
        </div>  
        <div className="horizontal-layer hlayer4 abouttext" data-depth='0.2' data-type='parallax' data-leftoffset='2200' data-bottomoffset='10'>
            <img src={layoutabouttext}/>
        </div>  
        <div className="horizontal-layer hlayer4 abouttext" data-depth='0.2' data-type='parallax' data-leftoffset='2120' data-bottomoffset='220'>
            <img src={treec}/>
        </div>  
        <div className="horizontal-layer hlayer4 abouttext" data-depth='0.2' data-type='parallax' data-leftoffset='2250' data-bottomoffset='200'>
            {/* add fruits */}
            <img src={treebe}/>
        </div>        
        <div className="horizontal-layer hlayer4 abouttext" data-depth='0.2' data-type='parallax' data-leftoffset='2350' data-bottomoffset='170'>
            <img src={treed}/>
        </div>  
        
               

        <div className="horizontal-layer hlayer5 moon" data-depth='0.015' data-type='parallax' data-leftoffset='-300' data-topoffset='125'>
            <img src={moon} />
        </div> 

        <div className="horizontal-layer hlayer9" data-depth='0.3' data-type='parallax' data-leftoffset='7000' data-bottomoffset='0' data-height={this.state.dh + 50}
         style={{"backgroundImage": "url(" + building2 + ")"}}>  
        </div>

        <div className="horizontal-layer hlayer8 layerfloorpanel" data-depth='0.3' data-type='parallax' data-leftoffset='2000' data-bottomoffset='0'
            style={{"backgroundImage": "url(" + ironblocks + ")"}}>
        </div>

        <div className="horizontal-layer hlayer7 layerfloorpanel" data-depth='0.16' data-type='parallax' data-leftoffset='6000' data-bottomoffset='200'
            style={{"backgroundImage": "url(" + ironblocks + ")"}}>
        </div>

        <div className="horizontal-layer hlayer6 spaceship" data-depth='0.04' data-type='parallax' data-leftoffset='3200' data-bottomoffset='200'>
            <img src={spaceship}/>
        </div>

        <div id="spacey">
          <div class="tiwari" id = "spacey-right" style = {{"backgroundImage": "url(" + spaceyfwd + ")", width:"260px", height:"309px"}}>
          </div>
          <div class="tiwari" id = "spacey-left" style = {{"backgroundImage": "url(" + spaceybwd + ")", width:"260px", height:"309px"}}>
          </div>
        </div>
       <div className="social">
            <a target="_blank" href="https://facebook.com/xariniov9" className="icon-button facebook"><i className="icon-facebook"></i><span></span></a>
            <a target="_blank" href="https://linkedin.com/in/xariniov9" className="icon-button linkedin"><i className="icon-linkedin"></i><span></span></a>
            <a target="_blank" href="https://github.com/xariniov9/interactive-resume" className="icon-button github"><i className="icon-github"></i><span></span></a>
            <a target="_blank" href="https://codechef.com/users/xariniov9" className="icon-button code"><i className="icon-code"></i><span></span></a>
        </div>
        <img id="portrait-footer" src={portraitface}></img>
        <div className="control-text">
            <p>Use Up/Down arrow keys or swipe Left/Right</p>
        </div>
      </div>
    );
  }
}

export default Resume;
