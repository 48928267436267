import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import firebase from 'firebase';
import './addAdmin.css';

class AddAdmin extends Component{
  constructor(props) {
    super(props);
    this.state = {email: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubmit(event) {
    var _this = this;
    var addAdmin = firebase.functions().httpsCallable('addAdmin');
    addAdmin({email: this.state.email}).then(function(result) { 
        console.log(result); 
        _this.setState({email: ""});
    }).catch(error => {
        console.log(error);
      });;
    event.preventDefault();
  }

  componentDidMount() {

  }
  render() {
		return (
        <div className="FormCard">
            <div className="FormCardTitle">
                <p>Add Admin</p>
            </div>
            <div className="centerAlign">
                <hr className="coloredBar"></hr>
            </div>
            <div className = "FormCardBody">
                <form onSubmit={this.handleSubmit}>
                    <label>
                    Email : 
                    <input type="text" value={this.state.email} onChange={this.handleChange} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    )
  }
}

export default AddAdmin;
