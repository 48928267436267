import React, { Component } from 'react';
import firebase from 'firebase';

import './ProfileCard.css';
import ProfileImage from './prof-img1.jpg';
import ProfileImage2 from './prof-img2.jpg';

class ContactCard extends Component{
  state = {
    xariniov9 : 0,
    vipsharmavip : 0,
    currentLikes: 0
  }
  constructor(props) {
    super(props);
    this.likeInstructor = this.likeInstructor.bind(this);
  }

  componentDidMount() {
    var functions = firebase.app().functions('us-central1');
    var getNumLikes = functions.httpsCallable('getNumLikes');
    var _this = this;
    getNumLikes({teacher: "xariniov9"}).then(function(result) {
        _this.setState({xariniov9: result.data.likes });
    }).catch(error => {
        console.log(error);
    });

    getNumLikes({teacher: "vipsharmavip"}).then(function(result) {
      _this.setState({vipsharmavip: result.data.likes});
    }).catch(error => {
        console.log(error);
    });
  }

  likeInstructor(event, teacher) {
    var _this = this;
    if(this.state.currentLikes >= 5)
      return;
    this.setState({currentLikes: this.state.currentLikes+1});
    var likeTeacher = firebase.functions().httpsCallable('likeInstructor');
    likeTeacher({teacher: teacher}).then(function(result) { 
        console.log(result); 
        _this.setState({[teacher]: result.data.likes});
    }).catch(error => {
        if(error.code == "failed-precondition") {
          alert("please sign in to give a hi five!");
        }
        console.log(error);
      });;
    if(event && event.preventDefault)
      event.preventDefault();
  }
  render() {
		return (
          <div className="profile-card">
            <div class="pcard">
            <div class="pcard-header" style={{"backgroundImage": "url(" + ProfileImage + ")" }}>      
                  <div class="card-header-slanted-edge">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 200"><path class="polygon" d="M-20,200,1000,0V200Z" /></svg>
                      <a href="#" class="btn-follow" onClick={ (e) => this.likeInstructor(e, "xariniov9")}><span class="sr-only">Follow</span></a>
                  </div>
            </div>
      
            <div class="pcard-body">
                <h2 class="name">Himanshu Tiwari</h2>
                <h4 class="job-title">Product Developer</h4>
                <div class="bio">Start running at your maximum pace and keep accelerating.</div>
                <div class="social-accounts">
                  <a target="_blank" rel="noopener" href="https://www.facebook.com/xariniov9" aria-label="facebook profile of ht">
                    <i class="fa fa-facebook"></i>
                  </a>
                  <a target="_blank" rel="noopener" href="http://linkedin.com/in/xariniov9" aria-label="linkedin profile of ht">
                    <i class="fa fa-linkedin"></i>
                  </a>
                  <a target="_blank" rel="noopener" href="https://github.com/xariniov9" aria-label="github profile of ht">
                    <i class="fa fa-github"></i>
                  </a>
                  <a target="_blank" rel="noopener" href="https://www.codechef.com/users/xariniov9" aria-label="CodeChef profile of ht">
                    <i class="fa fa-code"></i>
                  </a>
                </div>
            </div>
      
            <div class="pcard-footer">
                <div class="stats">
                    <div class="stat">
                      <span class="label">CodeChef</span>
                      <span class="value">6 Star</span>
                    </div>
                    <div class="stat">
                      <span class="label">Students</span>
                      <span class="value">550+</span>
                    </div>
                    <div class="stat">
                      <span class="label">Hi Fives</span>
                      <span class="value">{this.state.xariniov9}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="pcard">
            <div class="pcard-header" style={{"backgroundImage": "url(" + ProfileImage2 + ")" }}>      
                  <div class="card-header-slanted-edge">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 200"><path class="polygon" d="M-20,200,1000,0V200Z" /></svg>
                      <a href="#" class="btn-follow" onClick={ (e) => this.likeInstructor(e, "vipsharmavip")}><span class="sr-only">Follow</span></a>
                  </div>
            </div>
      
            <div class="pcard-body">
                <h2 class="name">Vipul Sharma</h2>
                <h4 class="job-title">Lead Mentor</h4>
                <div class="bio">Learn from the master of CP and leave no edge case uncovered.</div>
                <div class="social-accounts">
                  <a target="_blank" rel="noopener" href="https://www.facebook.com/vipsharmavip" aria-label="facebook profile of vs">
                    <i class="fa fa-facebook"></i>
                  </a>
                  <a target="_blank" rel="noopener" href="https://www.codechef.com/users/vipsharmavip" aria-label="CodeChef profile of vs">
                    <i class="fa fa-code"></i>
                  </a>
                </div>
            </div>
      
            <div class="pcard-footer">
                <div class="stats">
                    <div class="stat">
                      <span class="label">CodeChef</span>
                      <span class="value">6 Star</span>
                    </div>
                    <div class="stat">
                      <span class="label">Problems</span>
                      <span class="value">3500+</span>
                    </div>
                    <div class="stat">
                      <span class="label">Hi Fives</span>
                      <span class="value">{this.state.vipsharmavip}</span>
                    </div>
                </div>
            </div>
        </div>
        </div>      
        )
    }
}

export default ContactCard;