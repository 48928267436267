import React, { Component, Suspense, lazy }  from 'react';
import './codewith.css';
import Footer from './footer/footer';
import Header from './header/header';
import Ellipse from './images/ellipse.png';
import pathdotted from './images/path-dotted.png';

//import StaticBg from './content-static/static-bg/staticbg';
import ProfileCard from './content-static/profile-card/ProfileCard';

import * as firebase from 'firebase/app';
import 'firebase/auth';

const IntroCard = lazy(() => import('./content-static/content-main/introcard'));
const WelcomeCard = lazy(() => import('./content-static/welcome-card/welcome-card'));
const LeaderBoard = lazy(() => import('./leaderboard/leaderboard'));
const ReviewCards = lazy(() => import('./content-static/reviews/reviewcards'));
class CodeWith extends Component{
  state = {
    isSignedIn: false, // Local signed-in state.
    messageIdx: 0
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {this.setState({isSignedIn: !!user});}  
    );
    
    // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    // (function(){
    // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    // s1.async=true;
    // s1.src='https://embed.tawk.to/5d3625729b94cd38bbe8b88c/default';
    // s1.charset='UTF-8';
    // s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
    // })();

    const loader = document.querySelector('.loader-container');
    setTimeout(function () {
      loader.classList.add('loader--hide');
    }, 100);
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  render() {
    var contest = {currentContestCode: "AUG19B", currentContestName : "August'19 Long Challenge", previousContestCode: "JULY19B", previousContestName : "July'19 Long Challenge"};

    if (!this.state.isSignedIn) {
      return (
        <div className="main-app">
            <Header/>

            {/* <HoldOn/> */}
            <Suspense fallback={<div>Loading...</div>}>
              <WelcomeCard/>
            </Suspense>
            
            <div className="splash">
            <div className="section-lb1" style={{backgroundImage: "url(" + Ellipse + ")", width: "100%"}}>
                <Suspense fallback={<div>Loading...</div>}>
                  <IntroCard/>
                </Suspense>
            </div>
            <div className="section-reviews" style={{backgroundImage: "url(" + pathdotted + ")", width: "100%"}}>
              <Suspense fallback={<div>Loading...</div>}>
                <ReviewCards/>
              </Suspense>
            </div>  
            
            <div className="section-instructor">
              <div className="section-title">
                Our Team
              </div>
              <ProfileCard/>
              <div className="section-instructor-desc">
                <h1><span className="color-g">&nbsp;Start</span> Your Programming Pathway<span className="color-y">&nbsp;Today</span></h1>
                
                <p>Everybody in this country should learn to program a computer, because it teaches you how to think.</p>
                <p style={{fontSize: "14px"}}><em>- Steve Jobs</em></p>
              </div>
            </div>
            </div>      
            <Footer/>      
            {/* <StaticBg/> */}
      </div>
      );
    }
    return (
      <div className="main-app">
            <Header/>
            <Suspense fallback={<div>Loading...</div>}>
              <WelcomeCard/>
            </Suspense>
            <div className="splash">
              <div className="section-lb" style={{backgroundImage: "url(" + Ellipse + ")", width: "100%"}}>
                
                
                
                <Suspense fallback={<div>Loading...</div>}> 
                  <LeaderBoard contest = {contest}/>
                </Suspense>
              </div>
                         

              <div className="section-reviews" style={{backgroundImage: "url(" + pathdotted + ")", width: "100%"}}>
                <Suspense fallback={<div>Loading...</div>}>
                  <ReviewCards/>
                </Suspense>
              </div>  
              
              <div className="section-instructor">
                <div className="section-title">
                  Our Team
                </div>
                <ProfileCard/>
                <div className="section-instructor-desc">
                  <h1><span className="color-g">&nbsp;Start</span> Your Programming Pathway<span className="color-y">&nbsp;Today</span></h1>
                  
                  <p>Everybody in this country should learn to program a computer, because it teaches you how to think.</p>
                  <p style={{fontSize: "14px"}}><em>- Steve Jobs</em></p>
                </div>
              </div>
            </div> 
            <Footer/>     
            {/* <StaticBg/> */}
      </div>
      )
  }
}

export default CodeWith;
