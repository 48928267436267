import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import firebase from 'firebase';
import './addProblem.css';

class AddProblem extends Component{
  constructor(props) {
    super(props);
    this.state = {
        topic: "",
        // problem part
            isMat : false,
            title : "",
            statement: "",
            link: "",
            hint: "",
            instructions: "",
            level: "",
            author: "",
        topics: [],
        validationError: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleSubmit(event) {
   var addProblem = firebase.functions().httpsCallable('addProblem');
    var _this = this
    addProblem({
        topic: this.state.topic,
        problem : {
            isMat : false,
            title : this.state.title,
            statement: this.state.statement,
            link: this.state.link,
            hint: this.state.hint,
            instructions: this.state.instructions,
            level: this.state.level,
            author: "",
            lvl: parseInt(this.state.level)
        }
    }).then(function(result) { 
        _this.setState({
            topic: "",
        
            isMat : false,
            title : "",
            statement: "",
            link: "",
            hint: "",
            instructions: "",
            level: "",
            author: "",
            author: "",
            validationError: ""        
        });
        console.log(result); 
    }).catch(function(error) {
        console.log("Error in submitting problem: ", error);
        return {error: error};
    });
    event.preventDefault();
  }

  componentDidMount() {
    var getAllTopics = firebase.functions().httpsCallable('getAllTopics');
    var _this = this;
    getAllTopics().then(function(result) { 
      console.log(result.data.result); 
      let topicsFromApi = result.data.result.map(topic => { return {value: topic, display: topic} });
      _this.setState({ topics: [{value: '', display: '(Select topic)'}].concat(topicsFromApi) });
     }).catch(error => {
        console.log(error);
      });;

  }
  render() {
		return (
        <div className="FormCard addProblemCard">
            <div className="FormCardTitle">
                <p>Add Problem</p>
            </div>
            <div className="centerAlign">
                <hr className="coloredBar"></hr>
            </div>
            <div className = "FormCardBody">
                <form onSubmit={this.handleSubmit}>
                    <label>
                    Topic : 
                    <input type="text" name="topic" disabled={true} value={this.state.topic} onChange={this.handleChange}/>
                    </label>

                    <select value={this.state.topic} 
                    onChange={(e) => this.setState({topic: e.target.value, validationError: e.target.value === "" ? "You must select a topic" : ""})}>
                        {this.state.topics.map((topic) => <option key={topic.value} value={topic.value}>{topic.display}</option>)}
                    </select>
                    <br></br>

                    <label>
                    Title : 
                    <input type="text" name="title" value={this.state.title} onChange={this.handleChange}/>
                    </label>
                    <br></br>
                    
                    <label>
                    Statement : 
                    <textarea cols={40} rows={10} name="statement" value={this.state.statement} onChange={this.handleChange}/>
                    </label>
                    <br></br>

                    <label>
                    Link : 
                    <input type="text" name="link" value={this.state.link} onChange={this.handleChange}/>
                    </label>
                    <br></br>

                    <label>
                    Hint : 
                    <textarea cols={40} rows={5} name="hint" value={this.state.hint} onChange={this.handleChange}/>
                    </label>

                    <br></br>
                    <label>
                    Instructions : 
                    <textarea cols={40} rows={5} name="instructions" value={this.state.instructions} onChange={this.handleChange}/>
                    </label>

                    <br></br>
                    <label>
                    Level : 
                    <input type="text" name="level" value={this.state.level} onChange={this.handleChange}/>
                    </label>
                    <br></br>

                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    )
  }
}

export default AddProblem;
