import React, { Component } from 'react';
import firebase from 'firebase';
import './Pathway.css';

import './problem-sidebar/ProblemSidebar';
import ProblemSidebar from './problem-sidebar/ProblemSidebar';
import Problem from './problem-page/ProblemPage';

class Pathway extends Component{
    state = {
        isCollapsed: false,
        currentId : "",
        currentCard : {
            id : "",
            isProblem : true,
            description : `Hi sir please solve this problem using recursion. This is a very good problem. Mattis enim ut tellus elementum sagittis vitae et leo.
            Molestie nunc non blandit massa.
            <pre><code>
                #include < stdio.h>
                int main() {
                    printf("Hello World");
                    return 0;
                }
            </code></pre> Dignissim convallis aenean et tortor at risus viverra adipiscing. At varius vel pharetra vel turpis nunc. Venenatis cras sed felis eget velit. Congue nisi vitae suscipit tellus mauris a. Ultrices mi tempus imperdiet nulla. Risus at ultrices mi tempus imperdiet nulla. Faucibus vitae aliquet nec ullamcorper sit amet risus nullam. Egestas diam in arcu cursus euismod quis. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus. Tempor orci dapibus ultrices in iaculis nunc.`,
            title : "Sample Problem",
            link : "https://codechef.com/problems/XYZABC/",
            author : "Himanshu Tiwari",
            tags : [],
            hint : "",
            solution : "",
            solvedBy : 20,
            markedComplete : false,
            markedFavorite : false,
            difficulty : "Medium"
        }
    };
    constructor(props) {
        super(props);
        this.onCollapsePathway = this.onCollapsePathway.bind(this);
        this.onOpenPathway = this.onOpenPathway.bind(this);
    }

    onCollapsePathway(event, id) {
        this.setState({isCollapsed: true, currentId: id});
        window.scrollTo(0, 0);

        // const loader = document.querySelector('.welcome-card');
        // loader.classList.add('loader--hide');
    }

    onOpenPathway () {
        this.setState({isCollapsed: false, currentId: "Bye"});
        window.scrollTo(0,0);
        // const loader = document.querySelector('.welcome-card');
        // loader.classList.remove('loader--hide');
    }

    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => {this.setState({isSignedIn: !!user});}  
          );
        //   var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        //   (function(){
        //   var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        //   s1.async=true;
        //   s1.src='https://embed.tawk.to/5d3625729b94cd38bbe8b88c/default';
        //   s1.charset='UTF-8';
        //   s1.setAttribute('crossorigin','*');
        //   s0.parentNode.insertBefore(s1,s0);
        //   })();
    }
    render() {
        return (
        <div className="pathway-parent">
        {
            this.state.isCollapsed &&
            <div className="back-to-pathway" onClick={this.onOpenPathway}>
                Pathway
            </div>
        }    
        {
            this.state.isCollapsed == false &&
            <section id="cd-timeline" class="cd-container">
                <div class="cd-timeline-block">
                    <div class="cd-timeline-img cd-picture">
                    </div>
    
                    <div class="cd-timeline-content" onClick = {(e) => this.onCollapsePathway(e, "hi")}>
                        <h2>Sample Problem 1</h2>
                        <div class="timeline-content-info">
                        <span class="timeline-content-info-title">
                            <i class="fa fa-certificate" aria-hidden="true"></i>
                            Himanshu Tiwari
                        </span>
                        <span class="timeline-content-info-date">
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            May, 2019 - Present
                        </span>
                        </div>
                        <p>
                            We are currently building this website, if you want to contribute to this project contact the author. We would love to hear your ideas.
                        </p>    
                        <p>
                            This is how problem cards will be visible on the pathway. You will be able to click on these cards to view the detailed problem/material.
                        </p>
                        <ul class="content-skills">
                            <li className="h-tag">Pre-Requisites</li>
                            <li>Loops</li>
                            <li>Conditions</li>
                            <li>I/O</li>
                            <li>Operators</li>
                        </ul>
                    </div> 
                </div>
                <div class="cd-timeline-block">
                    <div class="cd-timeline-img cd-picture">
                    </div>
    
                    <div class="cd-timeline-content" onClick = {(e) => this.onCollapsePathway(e, "hi")}>
                        <h2>Sample Problem 2</h2>
                        <div class="timeline-content-info">
                        <span class="timeline-content-info-title">
                            <i class="fa fa-certificate" aria-hidden="true"></i>
                            Himanshu Tiwari
                        </span>
                        </div>
                    </div> 
                </div>
            <div class="cd-timeline-block">
                <div class="cd-timeline-img cd-movie">
                </div>
    
                <div class="cd-timeline-content" onClick = {(e) => this.onCollapsePathway(e, "hi")}>
                    <h2>Title of section 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde?</p>
                </div> 
            </div>
    
            <div class="cd-timeline-block">
                <div class="cd-timeline-img cd-picture">
                </div> 
    
                <div class="cd-timeline-content" onClick = {(e) => this.onCollapsePathway(e, "hi")}>
                    <h2>Title of section 3</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi, obcaecati, quisquam id molestias eaque asperiores voluptatibus cupiditate error assumenda delectus odit similique earum voluptatem doloremque dolorem ipsam quae rerum quis. Odit, itaque, deserunt corporis vero ipsum nisi eius odio natus ullam provident pariatur temporibus quia eos repellat consequuntur perferendis enim amet quae quasi repudiandae sed quod veniam dolore possimus rem voluptatum eveniet eligendi quis fugiat aliquam sunt similique aut adipisci.</p>
                    <span class="cd-date">Jan 24</span>
                </div> 
            </div>
    
            <div class="cd-timeline-block">
                <div class="cd-timeline-img cd-location">
                </div> 
    
                <div class="cd-timeline-content" onClick = {(e) => this.onCollapsePathway(e, "hi")}>
                    <h2>Title of section 4</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                </div>
            </div> 
    
            <div class="cd-timeline-block">
                <div class="cd-timeline-img cd-location">
                </div> 
    
                <div class="cd-timeline-content" onClick = {(e) => this.onCollapsePathway(e, "hi")}>
                    <h2>Title of section 5</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum.</p>
                    <span class="cd-date">Easy</span>
                </div> 
            </div> 
    
            <div class="cd-timeline-block">
                <div class="cd-timeline-img cd-movie">
                </div>
    
                <div class="cd-timeline-content" onClick = {(e) => this.onCollapsePathway(e, "hi")}>
                    <h2>Final Section</h2>
                    <p>This is the content of the last section</p>
                    <span class="cd-date">Medium</span>
                </div> 
            </div>
            </section>
        
        }
        {
            this.state.isCollapsed && this.state.currentCard.isProblem &&
            <div className="problem-detail">
                <Problem problem = {this.state.currentCard}/>
                <div>
                    <ProblemSidebar problem = {this.state.currentCard}/>
                    <div className="back-to-pathway" onClick={this.onOpenPathway}>
                        Pathway
                    </div>
                </div>
            </div>         
        }
        </div>
        );
	}
}

export default Pathway;