import React, { Component } from 'react';
import './header.css';
import codewithlogo from './images/new-logo.png';
import { findDOMNode } from 'react-dom';

import * as firebase from 'firebase/app';
import 'firebase/auth';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import FirebaseConfig from '../../../fconf.js';
import AppConfig from '../../../appconf.js';

firebase.initializeApp(FirebaseConfig);


class Header extends Component{

  state = {
    isSignedIn: false, // Local signed-in state.
    photoURL: ''
  };
  uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {
          if(user != null) {
            this.setState({isSignedIn: !!user, photoURL: user.photoURL});
          } else {
            this.setState({isSignedIn: false}) 
          }
        }
    );
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  render() {
		if (!this.state.isSignedIn) {
      return (
        <div>
          <div className = "Header Header-bg">
            <a className="header-left-content" href="\">
              <img className="codewithlogo" src={codewithlogo} alt="logo"></img>
              <div className = "header-title">
              {AppConfig.appName}
              </div>
            </a>
            <div id="button-sign-in">
              <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className = "Header Header-bg">
          
          <a className="header-left-content" href="\">
            <img className="codewithlogo" src={codewithlogo} alt="logo"></img>
            <div className = "header-title">
                {AppConfig.appName}
            </div>
          </a>
          <div className = "header-right-content">
            <div className="homebutton">
              <a className="btnClrd orange" href="/" >Home</a>
            </div>
            <div className="signoutBtn">
              <a className="btnClrd orange" onClick={() => firebase.auth().signOut()} >Sign Out</a>
            </div>
            <div className="loggedInUserDetails">
              <div className="displayPhoto">
                <img id="loggedInUserImage" src={this.state.photoURL} alt="loggedin user"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
	}
}

export default Header;