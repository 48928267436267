import React, { Component } from 'react';
import firebase from 'firebase';
import './Vote.css';
import Header from '../codewith/header/header';
import CreateCommunity from './AdminTools/CreateCommunity';
import JoinCommunity from './JoinCommunity';
import CommunityDetails from './CommunityDetails';
import 'firebase/auth';
import Modal from 'react-responsive-modal';

class Vote extends Component{
  state = {
    isSignedIn: false, // Local signed-in state.
    messageIdx: 0,
    myCommunities: [],
    selectedCommunity : '',
    selectedCommunityName : '',
    newMessage: '',
    user : {},
    chatMessages: [],
    chats: [],
    readError : null,
    chatWindowOpen: false,
    communityListOpen: false,
    modalOpen: false,
    createModal: false,
    joinModal: false
  };
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  registerChat(community, uid) {
    debugger;
    if(!uid || !community)
      return;
    return firebase.database().ref("chat/members/"+community).update({
      [uid] : true
    })
  }

  onClickChatWindow() {
    this.setState({chatWindowOpen: !this.state.chatWindowOpen});
  }

  onOpenModal(event,data) {
    this.setState({modalOpen: true});
    if(data === "c") {
      this.setState({createModal: true});
    } else if(data === "j") {
      this.setState({joinModal: true});
    }
  }

  onCloseModal() {
    this.setState({modalOpen: false, createModal: false, joinModal: false});
  }
  componentDidMount() {
    document.title = this.props.title;
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        this.setState({isSignedIn: !!user});
        if(user){                                                                                                                         
          user.getIdTokenResult(true)
          .then((res)=> {
            debugger;
            var userObj = {username: res.claims.name, useremail: res.claims.email, userpicture: res.claims.picture, uid: res.claims.user_id};
            this.setState({isSignedIn: !!user, isAdmin: res.claims.moderator, user: userObj});
            var getMyCommunities = firebase.functions().httpsCallable('getMyCommunities');
            var _this = this;
            getMyCommunities().then(function(result) { 
                _this.setState({myCommunities : result.data.myCommunities.myCommunities}); //add checks for null etc
                result.data.myCommunities.myCommunities.map(i => {
                  _this.registerChat(i.id, res.claims.user_id);
                })
            }).catch(error => {
                console.log(error);
            });;
          }); 

          
        }  
      }
      
    );
    var _this = this;
    this.scrollToBottom();
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSelectCommunity = this.onSelectCommunity.bind(this);
    this.registerChat = this.registerChat.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.onClickChatWindow = this.onClickChatWindow.bind(this);
    const loader = document.querySelector('.loader-container');
    setTimeout(function () {
      loader.classList.add('loader--hide');
    }, 100);
  }
  
  componentDidUpdate() {
    this.scrollToBottom();
  }
  scrollToBottom() {
    if(this.messagesEnd){
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }  
  }
  sendMessage() {
    
    firebase.database().ref("chat/"+this.state.selectedCommunity+"/").push({
      u : this.state.user.uid,
      m : this.state.newMessage,
      t : firebase.database.ServerValue.TIMESTAMP
    });
    this.setState({newMessage: ''});
  }
  componentWillUnmount() {
    this.setState({myCommunities: [], user: {} })
    this.unregisterAuthObserver();
  }
  onSelectCommunity(event, data) {
    firebase.database().ref("chat/"+this.state.selectedCommunity).off();
    this.setState({selectedCommunity: data.id, selectedCommunityName: data.name});
    try {
      firebase.database().ref("chat/"+data.id).orderByChild("t").limitToLast(20).on("value", snapshot => {
        let chats = [];
        snapshot.forEach((snap) => {
          chats.push(snap.val());
        });
        this.setState({ chats: chats });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }
  render() {
    var _this = this;
    const open = _this.state.modalOpen;
    const join = _this.state.joinModal;
    const create = _this.state.createModal;

    if (!this.state.isSignedIn) {
      return (
        <div className="vote">
            <Header/>
            <div>
              <h1 className="commCards">Please Login to continue..</h1>
            </div>
            
        </div>
      )
    } else {
      return (
        <div className="vote">
            <Header/>
            <button style={{ padding:"10px", position: "fixed", top: "60px"}} onClick = {(e) => {_this.setState({communityListOpen: !_this.state.communityListOpen})}}>{_this.state.communityListOpen ? "Close" : "Your Communities"}</button>
            <div className = "all-tab-container">
                <div className = "community-list">
                {this.state.communityListOpen && <div style={{"padding-top": "40px", color: "#385898", fontSize: "18px", backgroundColor: "#fff", border: 0}} className="selected-community comm-title-selected">
                  Your Communities
                </div>}
                {this.state.communityListOpen && <div className="selected-community comm-title-selected" style={{"fontWeight: 'normal', padding": "10px", color: "#385898", fontSize: "16px", backgroundColor: "#fff"}}>
                  <span onClick={(e) => {_this.onOpenModal(e, "c")}}>Create</span> | <span onClick={(e) => {_this.onOpenModal(e, "j")}}>Join</span>
                </div>}                {this.state.communityListOpen && this.state.myCommunities.map(function (i) {
                    if(_this.state.selectedCommunity == i.id) {
                      return <div className="selected-community">
                                <div className="comm-title-selected">
                                  {i.name}
                                </div>
                                <div className = "VoteCardBody">
                                    {i.id}
                                </div>
                            </div>
                    } else {
                        return  <div className="CommCard" onClick = {(e) => _this.onSelectCommunity(e, i)}>
                                  <div className="comm-title">
                                    {i.name}
                                  </div>
                                </div>
                    }
                  })
                }
              </div>  
              <CommunityDetails {...this.state.user} selectedCommunity = {this.state.selectedCommunity}/>
            </div>
            
            <div>
              <div class="chat-box">
                  <div class="chat-header" onClick={this.onClickChatWindow}>
                    <span class="name">{this.state.selectedCommunityName || "Community Chat Room"}</span>
                  </div>
                  { this.state.chatWindowOpen && <div class="chat-room" ref={(el) => { this.myRef = el; }}>
                    { this.state.chats.map(function(i) {
                          if(i.u == _this.state.user.uid) {
                            return <div class="message message-right">
                                    <div class="avatar-wrapper avatar-small">
                                      <img src={_this.state.user.userpicture} alt="avatar" />
                                    </div>
                                    <div class="bubble bubble-dark">
                                      {i.m}
                                    </div>
                                  </div>
                          } else {
                            return <div class="message message-left">
                                    <div class="bubble bubble-light">
                                      {i.m}
                                    </div>
                                  </div>
                          }
                      }) 

                    }
                    <div style={{ float:"left", clear: "both" }}
                      ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                    
                  </div>}
                  

                  { this.state.chatWindowOpen && <div class="type-area">
                    <form style={{display:"flex", "flexDirection": "row"}} onSubmit={(e) => {this.sendMessage(); e.preventDefault();}}>
                      <div class="input-wrapper" >
                        <input type="text" value={this.state.newMessage} name="newMessage" id="inputText" placeholder="Type messages here..." onChange={e => {_this.setState({newMessage: e.target.value})}} />
                      </div>
                      
                      <input type="submit" value="Send" class="button-send"/>
                    </form>
                    
                  </div>
                  }
                </div>
            </div>
            <Modal className="roundedBorder" open={create && open} onClose={this.onCloseModal} closeIconSize={14} center>
              <CreateCommunity/>
            </Modal>

            <Modal className="roundedBorder" open={join && open} onClose={this.onCloseModal} closeIconSize={14} center>
              <JoinCommunity/>
            </Modal>
        </div>
      )
    }
  }
}

export default Vote;
