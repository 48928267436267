import React, { Component } from 'react';
import firebase from 'firebase';
import './CPPath.css';
import Header from '../codewith/header/header';
import Pathway from './pathway/Pathway';

class CPPath extends Component{
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const loader = document.querySelector('.loader-container');
    setTimeout(function () {
      if(loader && loader.classList)
        loader.classList.add('loader--hide');
    }, 1500);
  }
  render() {
		return (
      <div className="pway">
           <Header/>
           <Pathway/>  
      </div>
    )
  }
}

export default CPPath;
