import React, { Component } from 'react';
import './admin.css';
import Header from '../codewith/header/header';
import { findDOMNode } from 'react-dom';
import AdminGreet from './admingreeting/admingreeting';
import AddAdmin from './addAdmin/addAdmin';
import AddProblem from './addProblem/addProblem';
import AddToLB from './addToLeaderboard/addToLB';
import UpdateLeaderboard from './updateLeaderboard/updateLeaderboard';

import $ from 'jquery';

import firebase from 'firebase';


class Admin extends Component{
  state = {
    isSignedIn: false, // Local signed-in state.
    isAdmin: false
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        //console.log(user.getIdToken());
        this.setState({isSignedIn: !!user, isAdmin: false});
        if(user){                                                                                                                         
          user.getIdTokenResult(true)
          .then((res)=> {
            this.setState({isSignedIn: !!user, isAdmin: res.claims.moderator});
            console.log(this.state);
          }); 
        }         
      }  
    );
    const loader = document.querySelector('.loader-container');
      setTimeout(function () {
        loader.classList.add('loader--hide');
    }, 1500);
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  render() {
    if (!this.state.isSignedIn || !this.state.isAdmin) {
      var msg = "Sorry you need to be admin to view this!"
      return (
        <div className="main-app1">
          <Header/>
          <AdminGreet message = {msg}/>
        </div>
      );
    }
    var msg = "Welcome back Mr. Admin!";
    return (
      <div className="main-app1">
        <Header/>
        <AdminGreet message = {msg}/>
        <AddAdmin/>
        {/* <AddToLB/> */}
        <AddProblem/>
        <UpdateLeaderboard/>
      </div>
    );
  }
}

export default Admin;
