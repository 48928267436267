import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import firebase from 'firebase';


class AddToLB extends Component{
  constructor(props) {
    super(props);
    this.state = {un: '', u: ""};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleSubmit(event) {
    var _this = this;
    var addUsernameForLeaderBoard = firebase.functions().httpsCallable('addUsernameForLeaderBoard');
    addUsernameForLeaderBoard({un: this.state.un, u: this.state.u}).then(function(result) { 
        console.log(result); 
        _this.setState({un: "", u: ""});
    }).catch(error => {
        console.log(error);
      });;
    event.preventDefault();
  }

  componentDidMount() {
  }
  render() {
		return (
        <div className="FormCard AddToLB">
            <div className="FormCardTitle">
                <p>Add To LeaderBoard</p>
            </div>
            <div className="centerAlign">
                <hr className="coloredBar"></hr>
            </div>
            <div className = "FormCardBody">
                <form onSubmit={this.handleSubmit}>
                    <label>
                    Username : 
                    <input type="text" name="un" value={this.state.un} onChange={this.handleChange} />
                    </label>
                    <br></br>
                    <label>
                    Name : 
                    <input type="text" name="u" value={this.state.u} onChange={this.handleChange} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    )
  }
}

export default AddToLB;
