import React, { Component } from 'react';
import firebase from 'firebase';
import 'firebase/auth';
import CreatePoll from './CreatePoll';
import { PieChart } from 'react-minimal-pie-chart';

class CommunityDetails extends Component{
  state = {
    community: {},
    user: {},
    selectedCommunity : '',
    updationInProgress: false,
    communityPolls: [], 
    communityVotes: {},
    voteCounts: {}
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var _this = this;

    var userObj = {username : this.props.username, useremail: this.props.useremail, userpicture: this.props.userpicture};
    this.setState({user: userObj});
    this.updateDetails = this.updateDetails.bind(this);
    this.castVote = this.castVote.bind(this);
    this.timeSince = this.timeSince.bind(this);
    this.addPollCallback = this.addPollCallback.bind(this);
    const loader = document.querySelector('.loader-container');

    setTimeout(function () {
      loader.classList.add('loader--hide');
    }, 100);
  }
  componentDidUpdate() {
    this.updateDetails();
  }
  castVote(e, pollId, option) {

    firebase.database().ref("votes/"+this.props.selectedCommunity+"/"+pollId).update({
      [this.props.uid] : option
    });
    e.preventDefault();
  }
  updateDetails() {
    if(this.state.updationInProgress == true || this.state.selectedCommunity === this.props.selectedCommunity) 
      return;
    var _this = this;
    var getCommunityDetails = firebase.functions().httpsCallable('getCommunityDetails');
    if(this.props.selectedCommunity && this.state.updationInProgress === false) {
      this.setState({updationInProgress: true})
      getCommunityDetails({id : this.props.selectedCommunity}).then(function(result) { 
          _this.setState({selectedCommunity: _this.props.selectedCommunity});
          var userObj = {username : _this.props.username, useremail: _this.props.useremail, userpicture: _this.props.userpicture};
          _this.setState({user: userObj});      
          _this.setState({community : result.data.community, updationInProgress: false}); //add checks for null etc
      }).catch(error => {
        _this.setState({selectedCommunity: _this.props.selectedCommunity});
        var userObj = {username : _this.props.username, useremail: _this.props.useremail, userpicture: _this.props.userpicture};
        _this.setState({user: userObj});    
        _this.setState({updationInProgress: false}); //add checks for null etc
          console.log(error);
      });;

      var getPollsFromCommunity = firebase.functions().httpsCallable('getPollsFromCommunity');
      getPollsFromCommunity({community : this.props.selectedCommunity}).then(function(result) { 
        _this.setState({communityPolls : result.data.polls});
      }).catch(error => {
          _this.setState({communityPolls: []});
          console.log(error);
      });;
      firebase.database().ref("votes/"+this.props.selectedCommunity).off();
      try {
        firebase.database().ref("votes/"+this.props.selectedCommunity).on("value", snapshot => {
          let communityVotes = {};
          let voteCounts = {}
          snapshot.forEach((snap) => {
            if(!communityVotes[snap.ref.key]) {
              communityVotes[snap.ref.key] = {};
            }
            communityVotes[snap.ref.key] = snap.val();
            
            if(!voteCounts[snap.ref.key])
                voteCounts[snap.ref.key] = {};
            for(var key in snap.val()) {
              if(!voteCounts[snap.ref.key][snap.val()[key]]) {
                voteCounts[snap.ref.key][snap.val()[key]] = 0;
              }
              voteCounts[snap.ref.key][snap.val()[key]] = voteCounts[snap.ref.key][snap.val()[key]] + 1; 
            }
            
          });
          this.setState({voteCounts: voteCounts})
          this.setState({ communityVotes: communityVotes });
        });
      } catch (error) {
        this.setState({ readError: error.message });
      }

    }
  }

  timeSince(lower) {
    var upper = Math.floor(Date.now()/1000);
    var seconds = upper - lower;
    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  addPollCallback(newPoll) {
    var pollsOld = this.state.communityPolls;
    console.log(this.state.communityPolls);
    var pollObj = newPoll.poll.poll;
    if(!pollObj)
      return;
    pollObj.pollId = newPoll.poll.pollId;
    pollObj.co = {};
    pollObj.co._seconds = Math.floor(Date.now()/1000);
    
    if(pollsOld.list){
      pollsOld.list.unshift({
        options: pollObj.options,
        on: pollObj.on,
        oimg: pollObj.oimg,
        community: pollObj.community,
        ue: pollObj.ue,
        co: pollObj.co,
        title: pollObj.title,
        pollId: pollObj.pollId
      });
    } else {
      pollsOld.list = [];
      pollsOld.list.unshift(
        pollObj
      );
    }
    this.setState({communityPolls: pollsOld})
    console.log(newPoll);
    console.log(this.state.communityPolls);
  }

  componentWillUnmount() {
    
  }

  render() {
      var _this = this;
     /* if(this.state.selectedCommunity !== this.props.selectedCommunity) {
        this.updateDetails(this.props.selectedCommunity);
      }*/
      if(this.state.community.name) {
        return (
          <div className="comm-detail-body">
          
          <div style={{backgroundColor: "#121212", color: "#fff"}}>    
              <div className="member-body">
              <div style={{backgroundColor: "#fff", padding: "32px 5px 33px 0"}} className="VoteCardTitle b-scr">Members</div>
              <table>
              <tbody>
                  <tr><td></td><td className = "b-scr"></td></tr>
                  {this.state.community && this.state.community.members && this.state.community.members.map(function (i) {
                  if(i.email === _this.state.community.owner)
                      return <tr>
                      <td><img className="member-picture" src={i.picture} ></img></td>
                      <td className = "b-scr">{i.name}</td>
                      </tr>;
                  else 
                      return <tr>
                      <td><img className="member-picture" src={i.picture} ></img></td>
                      <td className="b-scr">{i.name}</td>
                  </tr>;
                  })}
              </tbody>
              </table>
              </div>
            </div>
            <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
            <div className="right-panel">

              
            <div className="LBContentCard-right comm-name-big">
                <div>
                    <div className="Comm-Name-Title">{this.state.community.name}</div>
                </div>
              </div>
              <div className="LBContentCard-right">
                <div className="member-body">
                  <CreatePoll callBack={this.addPollCallback} username={this.props.username} useremail={this.props.useremail} userpicture={this.props.userpicture} community = {this.state.selectedCommunity}/>
                </div>
              </div>
              
              {this.state.communityPolls.list && this.state.communityPolls.list.length > 0 && this.state.communityPolls.list.map(function (i) {
                  return <div className="LBContentCard-right">
                    <div className="comm-detail-body" style={{margin: "10px"}}>
                      <img className="member-picture" src={i.oimg} alt="dp"></img>
                      <form style={{width: "100%"}}>
                          <div className="VoteCardTitle" style={{"fontFamily": "Helvetica, Arial, sans-serif", color:"#385898"}}>{i.on}</div>
                          <p style={{fontSize: "12px"}}>{_this.timeSince(i.co._seconds) }</p>
                          <p name="title" style={{"border": 0, "fontFamily": "Helvetica, Arial, sans-serif", "fontSize": "16px", width: "100%", paddingRight: "11px", textAlign: "justify", margin: "10px 0 10px 0", color:"#223"}}>{i.title}</p>
                          <ul className="option-tags">
                              {i.options.map(function (j) {
                                  if(_this.state.communityVotes[i.pollId] && _this.state.communityVotes[i.pollId][_this.props.uid] == j.id)
                                  {  return <div className="poll-selected" onClick={(e) => {_this.castVote(e, i.pollId, j.id); }}>
                                      <li style={{backgroundColor: "#1fa3ec", color: "#fff"}}>
                                          {j.title}
                                          {
                                        _this.state.voteCounts[i.pollId] && _this.state.voteCounts[i.pollId][j.id] && 
                                          <div className = "poll-counter">{_this.state.voteCounts[i.pollId][j.id]}</div> ||
                                          <div className = "poll-counter">0</div>
                                        }
                                      </li>
                                      
                                    </div>   
                                  } else {
                                    return <div onClick={(e) => {_this.castVote(e, i.pollId, j.id); }}>
                                    <li>
                                        {j.title}
                                        {
                                        _this.state.voteCounts[i.pollId] && _this.state.voteCounts[i.pollId][j.id] && 
                                          <div className = "poll-counter">{_this.state.voteCounts[i.pollId][j.id]}</div> ||
                                          <div className = "poll-counter">0</div>
                                        }
                                    </li>
                                  </div>  
                                  }    
                              })}
                          </ul>
                          {/* <PieChart
                            animationDuration={500}
                            animationEasing="ease-out"
                            center={[
                              50,
                              50
                            ]}
                            data={[
                              {
                                color: '#E38627',
                                title: 'One',
                                value: 10
                              },
                              {
                                color: '#C13C37',
                                title: 'Two',
                                value: 15
                              },
                              {
                                color: '#6A2135',
                                title: 'Three',
                                value: 20
                              }
                            ]}
                            labelPosition={100}
                            lengthAngle={360}
                            lineWidth={25}
                            paddingAngle={0}
                            radius={50}
                            rounded
                            startAngle={0}
                            style={{
                              height: '100px'
                            }}
                            viewBoxSize={[
                              100,
                              100
                            ]}
                            labelStyle={{
                              fontFamily: 'sans-serif',
                              fontSize: '5px'
                            }}
                            label={function noRefCheck(){}}
                            
                          />; */}
                      </form>                
                  </div>
                </div>
              })}          

            </div>

            </div>
          </div>
  
          )
  
      }
      else {
        return (
          <h2>Please join a community or select one of your community above!</h2>
        )
      }
    
  }
}

export default CommunityDetails;
