import React, { Component } from 'react';
import CCLogo from './cc-logo.png';
import './ProblemPage.css';

class Problem extends Component{
    state = {
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {
       
    }
    render() {
        return (
        <div className="problem">
            <div className="problem-title">
                {this.props.problem.title}
            </div>
            
            <div className = "problem-description" dangerouslySetInnerHTML={{__html: this.props.problem.description}}>
                
            </div>
            {
            this.props.problem.link && this.props.problem.link != "" &&
            <div className = "link-preview">
                <a className="link-anchor" target="_blank" href={this.props.problem.link}>
                    <div className="image-container" style={{backgroundImage: "url(" + CCLogo + ")"}}>

                    </div>
                    <div className="title">
                        Problem Page
                    </div>
                    <div className="link">
                        {this.props.problem.link}
                    </div>
                </a>
            </div>
            }
        </div>
        );
	}
}

export default Problem;