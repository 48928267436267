import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import firebase from 'firebase';


class CreateCommunity extends Component{
  constructor(props) {
    super(props);
    this.state = {name: '', submitted: false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleSubmit(event) {
    var _this = this;
    if(this.state.submitted) {
        event.preventDefault();
        return;
    }
    if(!this.state.name || this.state.name.trim() === '') {
        window.alert("Add some name to the Community!");
    } else {
        var addNewCommunity = firebase.functions().httpsCallable('createCommunity');
        var obj = {owner : '', ownername: '', members: []};
        console.log(this.state.name);
        this.setState({submitted: true})
        addNewCommunity({name: this.state.name, obj: obj}).then(function(result) { 
            console.log(result); 
            _this.setState({id: "", name: "", submitted: false});
            
        }).catch(error => {
            console.log(error);
            _this.setState({submitted: false});
        });;
    }
    event.preventDefault();
  }

  componentDidMount() {
  }
  render() {
		return (
        <div>
            <div className="VoteCardTitle">
                Create Community
            </div>
            <div>
                <form onSubmit={this.handleSubmit}>
                    Name
                    <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    )
  }
}

export default CreateCommunity;
