import React, { Component } from 'react';
import './ProblemSidebar.css';

class ProblemSidebar extends Component{
    state = {
    };
    constructor(props) {
        super(props);
        this.markComplete = this.markComplete.bind(this);
    }

    markComplete(event, id) {

    }

    componentDidMount() {
       
    }
    render() {
        return (
        <div className="problem-sidebar">
            <div className="problem-title">
                {this.props.problem.title}
            </div>
            <div className = "solved-by">
                Solved By : {this.props.problem.solvedBy}
            </div>
            <div className = "problem-difficulty">
                Difficulty : {this.props.problem.difficulty}
            </div>
            <div className = "contributor">
                Contributor : {this.props.problem.author}
            </div>
            <ul class="problem-tags">
                <li className="h-tag">Pre-Requisites</li>
                <li>Loops</li>
                <li>Conditions</li>
                <li>I/O</li>
                <li>Operators</li>
            </ul>
            <div className = "problem-option-box">
                <div className = "mark-complete">Mark Completed</div>
                <div className = "mark-favorite">Like</div>
            </div>
        </div>
        );
	}
}

export default ProblemSidebar;