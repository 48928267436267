import React, { Component } from 'react';
import './footer.css';
import codewithlogo from './logo-light.png';
import AppConfig from '../../../appconf.js';

class Footer extends Component{

  state = {
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }
  render() {
    return (
      <div>
        <div className = "footer">

        <a className="footer-logo" href="\">
            <img class="codewithlogo" src={codewithlogo} alt="logo"></img>
            <div className = "footer-title">
                {AppConfig.appName}
            </div>
        </a>
        <div className = "footer-ref">
            <div className="f-ref-r">
                <div className="f-ref-i">
                    Jobs
                </div>
                <div className="f-ref-i">
                    Privacy Policy
                </div>
                <div className="f-ref-i">
                    Terms of Use
                </div>
                <div className="f-ref-i">
                © {AppConfig.appName} &nbsp; 2019
                </div>
            </div>
        </div>

        <div class="footer-social-accounts">
                <a target="_blank" rel="noopener" href="https://www.facebook.com/programmingcompetitive" aria-label="Our facebook profile">
                    <i class="fa fa-facebook"></i>
                </a>
                <a target="_blank" rel="noopener" href="http://linkedin.com/in/xariniov9" aria-label="Our LinkedIn profile">
                    <i class="fa fa-linkedin"></i>
                </a>
                <a target="_blank" rel="noopener" href="https://instagram.com/programming.competitive" aria-label="Our Instagram profile">
                    <i class="fa fa-instagram"></i>
                </a>
            </div>
        </div>
      </div>
    );
	}
}

export default Footer;