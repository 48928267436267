const FirebaseConfig = {
  apiKey: "AIzaSyBoCbCtG9s2q5wKGvxGv2tLSPXY14Ap3ys",
  authDomain: "himanshu-tiwari.firebaseapp.com",
  databaseURL: "https://himanshu-tiwari.firebaseio.com",
  projectId: "himanshu-tiwari",
  storageBucket: "himanshu-tiwari.appspot.com",
  messagingSenderId: "1091825633305",
  appId: "1:1091825633305:web:3c0e13320b5856c1"
};

export default FirebaseConfig;