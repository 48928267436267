import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import firebase from 'firebase';


class UpdateLeaderboard extends Component{
  constructor(props) {
    super(props);
    this.state = {contestCode: '', LBData: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleSubmit(event) {
    var updateLB = firebase.functions().httpsCallable('updateLB');
    var _this = this;

    const documentData = Object.assign({}, JSON.parse(this.state.LBData));
    const d =  JSON.parse(this.state.LBData);
    for (const key in d) {
      if (key != "list") {
        delete documentData[key];
      }
    }
    console.log(documentData);

    updateLB({json: {
      contestCode: this.state.contestCode,
      LBData : {
        [this.state.contestCode] : documentData
      }
    }}).then(function(result) { 
        console.log(result); 
        _this.setState({contestCode: '', LBData: ''});
    }).catch(error => {
        console.log(error);
    });;
    event.preventDefault();
  }

  componentDidMount() {
    
  }
  render() {
		return (
        <div className="FormCard UpdateLB">
            <div className="FormCardTitle">
                <p>Update LeaderBoard</p>
            </div>
            <div className="centerAlign">
                <hr className="coloredBar"></hr>
            </div>
            <div className = "FormCardBody">
                <form onSubmit={this.handleSubmit}>
                    <label>
                    contestCode : 
                    <input type="text" name="contestCode" value={this.state.contestCode} onChange={this.handleChange} />
                    </label>
                    <br></br>

                    <label>
                    lbData : 
                    <textarea cols={40} rows={20} name="LBData" value={this.state.LBData} onChange={this.handleChange}/>
                    </label>

                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    )
  }
}

export default UpdateLeaderboard;
