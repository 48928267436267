import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import firebase from 'firebase';


class JoinCommunity extends Component{
  constructor(props) {
    super(props);
    this.state = {id: '', submitted: false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  handleSubmit(event) {
    var _this = this;
    if(this.state.submitted) {
        event.preventDefault();
        return;
    }
    if(!this.state.id || this.state.id.trim() === '') {
        window.alert("Please enter the community code");
    } else {
        var joinCommunity = firebase.functions().httpsCallable('joinCommunity');
        this.setState({submitted: true})
        joinCommunity({id: this.state.id}).then(function(result) { 
            console.log(result);   
            _this.setState({id: '', submitted: false});         
        }).catch(error => {
            console.log(error);
            _this.setState({submitted: false});
        });;
    }
    event.preventDefault();
  }

  componentDidMount() {
  }
  render() {
		return (
        <div>
            <div className="VoteCardTitle">
                Join Community
            </div>
            <div>
                <form onSubmit={this.handleSubmit}>
                    Code
                    <input type="text" name="id" value={this.state.id} onChange={this.handleChange} />
                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    )
  }
}

export default JoinCommunity;
