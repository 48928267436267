import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import './admingreeting.css';
class AdminGreet extends Component{
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }
  render() {
		return (
            <div className = "GreetCard">            
              <p>
                  {this.props.message}
              </p>
          </div>
    )
  }
}

export default AdminGreet;
